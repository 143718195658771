<template>
  <div class="marksheet">
    <div class="flex flex-between">
      <h1 class="fs-20 fw-600 truncate" style="max-width: 1030px">
        Test Analysis <span style="color: rgb(100, 44, 144)"> / Question Wise</span>
      </h1>
      <a class="back-btn waves-effect waves-light btn getclassbutton" @click="Goback()"
        ><span class="Tiny material-icons" style="position: relative; top: 7px; margin-right: 2px"
          >arrow_back</span
        >Go Back</a
      >
    </div>
    <div class="row" style="margin: 24px 0 12px 0">
      <div class="col l2 m3 s6 offset-l8 offset-m6" style="padding: 0" v-if="details.length > 0">
        <button class="btn waves-effect waves-light fs-14 fw-600 download-btn" @click="showChart()">
          Show Chart
        </button>
      </div>
      <div class="col l2 m3 s6" style="padding: 0" v-if="details.length > 0">
        <button
          class="btn waves-effect waves-light fs-14 fw-600 download-btn"
          @click="downloadCsv()"
        >
          Download CSV file
        </button>
      </div>
    </div>
    <div class="table-main scroll-horizontal w-100" v-if="details.length > 0">
      <div class="table-header" style="grid-template-columns: repeat(6, 142px)">
        <p class="fs-14 fw-500 center-item">Question</p>
        <p class="fs-14 fw-500 center-item">Section Name</p>
        <p class="fs-14 fw-500 center-item">Q.Serial No.</p>
        <div class="text-center" title="Correct">
          <img :src="require(`../assets/correct13.svg`)" alt="" class="icon" />
        </div>
        <div class="text-center" title="Incorrect">
          <img :src="require(`../assets/wrong13.svg`)" alt="" class="icon" />
        </div>
        <div class="text-center" title="Skipped">
          <img :src="require(`../assets/skip13.svg`)" alt="" class="icon" />
        </div>
      </div>
      <div
        class="student-detail white pointer"
        style="grid-template-columns: repeat(6, 142px)"
        v-for="(item, index1) in details"
        :key="index1"
      >
        <a
          class="tooltip center-item"
          data-tooltip="I am a tooltip"
          style="height: 23px"
          @click="showQuestionModal(item.QuestionId)"
        >
          <span class="material-icons material-symbols-outlined pointer">visibility</span>
          <span class="tooltiptext" style="left: 37px">View</span>
        </a>
        <p class="fs-14 fw-500 center-item">{{ item.SectionName }}</p>
        <span class="center-item">{{ item.QSerialNumber }}</span>
        <span class="center-item">{{ item.Correct }}</span>
        <span class="center-item">{{ item.Incorrect }}</span>
        <span class="center-item">{{ item.Skipped }}</span>
      </div>
    </div>
    <ViewQuestionModal
      :questionId="questionId"
      :selectedTab="selectedModalTab"
      v-if="showQuesViewModal"
      @closeModal="closeToggle($event)"
    />
    <!-- <div class="batch-graph" v-if="details.length>0"><bar-chart :chartData="chartData" :chartOptions="barChartOptions"></bar-chart></div> -->
    <div>
      <div class="chartCard" v-if="showChartPopup">
        <div class="closeIcon" @click="showChartPopup = false">
          <span class="material-icons publish-close pointer" style="color: #ffffff !important"
            >close</span
          >
        </div>
        <div class="chartBox">
          <canvas ref="myChart"></canvas>
        </div>
      </div>
    </div>
    <div class="portrait">
      <div class="valign-wrapper">
        <div class="valign">
          <div style="margin-bottom: 100px">
            <div class="rotate"></div>
            <h5 class="light">Please rotate your device</h5>
            <span style="white-space: initial"
              >We don't support portrait mode yet. Rotate your device to landscape mode for the best
              experience</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
;

<script>
import exportFromJSON from "export-from-json";
import { useToast } from "vue-toastification";
import {
  Chart,
  LinearScale,
  BarController,
  CategoryScale,
  BarElement,
  Legend,
  Tooltip,
} from "chart.js";
import Mobileapi from "../Mobileapi";
import ViewQuestionModal from "../components/ViewQuestionModal.vue";

const toast = useToast();

export default {
  components: {
    ViewQuestionModal,
  },
  data() {
    return {
      showChartPopup: false,
      instituteTestId: null,
      details: [],
      questionId: null,
      showQuesViewModal: false,
      selectedModalTab: 1,
      QuestionDiagramURL: "",
      conceptTabActive: 0,
      chartData: { labels: [], datasets: [] },
      chart: null,
    };
  },
  beforeMount() {
    document.body.style.backgroundColor = "#F6F8FB";
  },
  mounted() {
    if (this.instituteTestId === null) {
      this.instituteTestId = Number(this.$route.params.instituteTestId);
    }
    this.$store.dispatch("showLoader", true);
    Chart.register(LinearScale, BarController, CategoryScale, BarElement, Legend, Tooltip);
    Mobileapi.getTestAnalysisQuesWiseReport(this.instituteTestId, (response) => {
      if (response.responseCode === 200) {
        if (response.data.length > 0) {
          this.details = response.data[0];
          this.chartData.labels = this.details.map(
            (item) => `${item.QSerialNumber}-${item.SectionName}`,
          );
          const correctData = {
            label: "Correct",
            data: this.details.map((item) => item.Correct),
            backgroundColor: ["rgba(0, 128, 0, 0.2)"],
            borderColor: ["rgba(0, 128, 0, 1)"],
            borderWidth: 1,
          };
          const incorrectData = {
            label: "Incorrect",
            data: this.details.map((item) => item.Incorrect),
            backgroundColor: ["rgba(255, 0, 0, 0.5)"],
            borderColor: ["rgba(255, 0, 0, 1)"],
            borderWidth: 1,
          };
          const skippedData = {
            label: "Skipped",
            data: this.details.map((item) => item.Skipped),
            backgroundColor: ["rgba(128, 128, 128, 0.2)"],
            borderColor: ["rgba(128, 128, 128, 1)"],
            borderWidth: 1,
          };
          this.chartData.datasets.push(correctData, incorrectData, skippedData);
        } else {
          toast.error("Test analysis question wise details not available", {
            timeout: 2500,
          });
        }
      }
      if (response.responseCode === 500) {
        toast.error("Some error occured", {
          timeout: 2500,
        });
      }
      this.$store.dispatch("showLoader", false);
    });
  },
  methods: {
    showChart() {
      this.showChartPopup = true;
      this.$nextTick(() => {
        if (this.showChartPopup) {
          this.createChart();
        }
      });
    },
    createChart() {
      const config = {
        type: "bar",
        data: this.chartData,
        options: {
          plugins: {
            tooltip: { enabled: true },
          },
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              beginAtZero: true,
              stacked: true,
              ticks: {
                stepSize: 1,
                precision: 0,
              },
            },
          },
        },
      };
      const ctx = this.$refs.myChart.getContext("2d");
      // eslint-disable-next-line no-new
      new Chart(ctx, config);
    },
    closeToggle(event) {
      this.showQuesViewModal = event;
    },
    showQuestionModal(questionId) {
      this.showQuesViewModal = true;
      this.questionId = questionId;
      this.QuestionDiagramURL = "";
      this.selectedModalTab = 1;
      this.questionModal = true;
      this.conceptTabActive = 0;
    },
    Goback() {
      this.$router.push({
        name: "ManageInstituteTest",
        params: { isNavigate: 1 },
      });
    },
    downloadCsv() {
      const values = [
        "SectionName",
        "InstituteTestId",
        "InstituteTestPartId",
        "PartSerialNumber",
        "QSerialNumber",
        "QuestionId",
        "Correct",
        "Incorrect",
        "Skipped",
        "QuestionLatex",
      ];
      const fileName = "TestAnalysisQuestionWise";
      const exportType = exportFromJSON.types.csv;
      const fields = values;
      const data = this.details;
      exportFromJSON({
        data,
        fileName,
        fields,
        exportType,
      });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
.icon {
  height: 25px;
  width: 25px;
}
.rotate {
  background: url("../assets/landscape.png");
  height: 100px;
  width: 100px;
  margin: 0 auto;
}
.portrait {
  display: none;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 27px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  /* cursor: pointer; */
}
.tooltip {
  position: relative;
}
.tooltiptext {
  visibility: hidden;
  background-color: #3fbbb9;
  color: white;
  text-align: center;
  padding: 2px 8px;
  border-radius: 3px;
  z-index: 1;
  white-space: break-spaces;
  text-align: center;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  transition: 0.3s all ease;
  font-weight: 600;
  position: absolute;
  left: -15px;
  bottom: -32px;
}
.tooltiptext::before {
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  background: #3fbbb9;
  left: 42%;
  top: -5px;
  -webkit-backdrop-filter: #3fbbb9;
  backdrop-filter: #3fbbb9;
  transform: rotate(45deg);
  background: #3fbbb9;
  visibility: hidden;
  transition: 0.1s all ease;
  line-height: 20px;
}
.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptext::before {
  visibility: visible;
}
.marksheet {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box !important;
  margin-top: 28px;
}
.skyblue-text-clr {
  color: #3751ff;
}
.skyblue-light {
  color: #5c8eff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.back-btn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}
.btn:hover {
  box-shadow: none !important;
}
.download-btn {
  background-color: #16a085;
  text-transform: capitalize !important;
  float: right;
}
.table-main {
  display: grid;
  grid-gap: 12px;
}
.batch-graph {
  margin-top: 10px;
  padding: 10px;
  height: 500px !important;
  width: 100% !important;
}
.table-header,
.student-detail {
  padding: 12px 24px;
  background: #f3f3f3;
  border-radius: 4px 4px 0px 0px;
  display: grid;
  grid-gap: 46px;
}
.center-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.chartCard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartBox {
  width: 85%;
  height: 90%;
  background-color: #fff;
}

canvas {
  width: 100%;
  height: 100%;
}
.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
}
@media screen and (max-width: 600px) and (orientation: portrait) {
  .portrait {
    display: block;
    background: #3a322f;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1001;
    text-align: center;
    color: #ffffffe6;
    left: 0;
    padding: 0 30px;
  }

  canvas {
    display: none;
  }

  .chartCard {
    display: none;
  }
}
</style>
